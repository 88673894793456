<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
    aria-hidden="true"
  >
    <g fill="currentColor">
      <path
        d="M24 .001a23.891 23.891 0 1 0 17 7 24 24 0 0 0-17-7Zm0 43.636a19.636 19.636 0 1 1 19.636-19.636A19.636 19.636 0 0 1 24 43.637Z"
      />

      <path
        d="M34.9,22.409H14.082a2.082,2.082,0,1,0,0,4.164H34.9a2.082,2.082,0,1,0,0-4.164Z"
        transform="translate(-0.488 -0.488)"
      />
      <path
        class="plus opacity-100 transition-opacity group-open:opacity-0"
        d="M34.9,22.409H14.082a2.082,2.082,0,1,0,0,4.164H34.9a2.082,2.082,0,1,0,0-4.164Z"
        transform="translate(48.494 -0.488) rotate(90)"
      />
    </g>
  </svg>
</template>
